/* You can add global styles to this file, and also import other style files */

@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
/* @import "assets/css/bootstrap.min.css"; */
@import "assets/css/font-awesome.css";
@import "assets/css/revslider.css";
/* @import "assets/css/owl.carousel.css";
@import "assets/css/owl.theme.css"; */
@import "assets/css/jquery.bxslider.css";
@import "assets/css/jquery.mobile-menu.css";
/* @import "assets/css/style.css"; */
@import "assets/css/responsive.css";
@import "assets/css/custom-style.css";



form .ng-invalid.ng-touched,
form .ng-invalid.ng-touched .ck-editor__main {
    border: 1px solid #ff0005;
}

.invalid-feedback,
.text-danger {
    color: #ff0005
}

form .ck-editor__main,
form .ck.ck-content {
    height: 300px;
}

.normal-slider .slick-prev,
.normal-slider .slick-next {
    width: 40px;
    height: 40px;
    /* opacity: 0; */
    transition: .6s;
    background: rgba(0, 0, 0, .7);
    border-radius: 4px;


    background: grey !important;
    z-index: 100;
}

.normal-slider .slick-prev {
    left: 55px;
}

.normal-slider .slick-next {
    right: 55px;
}

.product-detail .product-slider-single-nav .normal-slider .slick-arrow.nextBtn {
    position: absolute;
    right: -30px !important;
    top: 23% !important;
}

.product-detail .product-slider-single-nav .normal-slider .slick-arrow.prevBtn {
    position: absolute;
    left: -30px !important;
    top: 23% !important;
    z-index: 10;
}

.normal-slider.slick-slider:hover .slick-prev {
    left: 15px;
    opacity: 1;
}

.normal-slider.slick-slider:hover .slick-next {
    right: 15px;
    opacity: 1;
}

.normal-slider .slick-prev:hover,
.normal-slider .slick-prev:focus,
.normal-slider .slick-next:hover,
.normal-slider .slick-next:focus,
.related .slick-prev:hover,
.related .slick-prev:focus,
.related .slick-next:hover,
.related .slick-next:focus {
    background: rgba(0, 0, 0, .7);
}

.normal-slider .slick-prev:hover::before,
.normal-slider .slick-prev:focus::before,
.normal-slider .slick-next:hover::before,
.normal-slider .slick-next:focus::before,
.related .slick-prev:hover::before,
.related .slick-prev:focus::before,
.related .slick-next:hover::before,
.related .slick-next:focus::before {
    color: #E4BD34;
}

.related .slick-track {
    display: flex;
    margin-left: 0 !important;
}

.normal-slider .slick-arrow {
    color: #E4BD34;
}

.normal-slider .slick-arrow.nextBtn,
.related .slick-arrow.nextBtn {
    position: absolute;
    right: 0;
    top: 48%;
}

.normal-slider .slick-arrow.prevBtn,
.related .slick-arrow.prevBtn {
    position: absolute;
    left: 0;
    top: 48%;
    z-index: 10;
}

.normal-slider .slick-dots {
    bottom: 15px;
}

.normal-slider .slick-dots li button:before {
    color: #C0DCC8;
    font-size: 15px;
}

.normal-slider .slick-dots li.slick-active button:before {
    color: #086F3E;
}

.categories .slick-arrow {
    width: 40px;
    height: 40px;
    z-index: 1;
    color: black;
}

.categories .slide-arrow {
    position: absolute;
    top: 50%;
    margin-top: -15px;
}

.categories button.slide-arrow {
    border-radius: 50% !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    color: #E4BD34 !important;
    border: none !important;
}

.categories button:hover {
    background-color: #191919 !important;
}

.categories .prev-arrow {
    left: -20px;
}

.categories .next-arrow {
    right: -20px;
}

.categories .slick-prev:before,
.slick-next:before {
    color: black;
}

.owl-carousel.owl-drag .owl-item {
    width: 210.8px !important;
}

.owl-carousel .owl-item .bbb_viewed_image img {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.owl-carousel .owl-item .bbb_viewed_image img:hover {
    /* transform: scale(1.1); */
    transition: all 0.3s ease-in-out;
}

.best_selling .owl-theme .owl-nav {
    margin-top: 0 !important;
}

.best_selling .owl-nav {
    position: absolute !important;
    top: calc(50% - 21px) !important;
    width: 107% !important;
    left: -21px !important;
}

@media (max-width: 991px) {
    .best_selling .owl-nav {
        left: 0 !important;
    }
}

.best_selling .owl-item {
    width: auto !important;
    margin-top: 0.2em;
    margin-bottom: 0.5em;
    cursor: pointer;
}

.best_selling .owl-nav div {
    position: absolute !important;
    top: 50% !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.best_selling .owl-nav div:hover {
    transition: 200ms !important;
    background-color: #191919 !important;
}

.best_selling .owl-prev {
    left: -11px !important;
    display: flex !important;
    background: #191919 !important;
}

.best_selling .owl-next {
    right: 32px !important;
    display: flex !important;
    background: #191919 !important;
}

.best_selling .owl-prev i,
.best_selling .owl-next i {
    margin: auto !important;
    color: #e4bd34 !important;
}

.bbb_viewed_image img {
    display: block;
    max-width: 100%;
    height: auto !important;
    width: 100%;
}

.related .slick-slide .bbb_viewed_image img {
    height: 190px !important;
}

.related .slick-slide {
    margin: 0 1px 0 0;
}

.bbb_viewed_content .discount {
    cursor: default;
}

.stock_badge {
    position: absolute;
    top: 5%;
    left: 4.5%;
    overflow: hidden;
}

.stock_badge .badge {
    border-radius: 0 !important;
}

.badge.red {
    background: #e44934;
    font-size: 10px !important;
    padding: 5px 69px !important;
}

.badge.green {
    background: #1c8a45;
}

@media (max-width: 768px) {
    .bbb_viewed_image img {
        height: 100% !important;
        width: 325px !important;
    }
}

@media (max-width: 480px) {
    .bbb_viewed_image img {
        /* width: 142px; */
    }

    .related .slick-slide {
        margin: 0;
    }
}

/****************************************/
/*********** Custom Style CSS ***********/
/****************************************/
.btn {
    border-radius: 0 !important;
}

.btn-primary,
.btn-primary:hover {
    color: #F8F8F8 !important;
}

.btn-dark,
.btn-dark:hover {
    color: #E4BD34 !important;
}

.btn.btn-outline-primary:hover {
    color: #F8F8F8 !important;
}

.btn.btn-outline-warning:hover {
    color: #191919 !important;
}

.btn.translate_btn {
    border: none !important;
}

.translate .dropdown-item:active {
    background-color: #1C8A45 !important;
    color: #F8F8F8 !important;
}

.login_btn.btn-link {
    text-decoration: none !important;
}

.login_btn.btn-link:hover {
    text-decoration: underline !important;
    color: white !important;
}

.btn-primary.btn:focus-visible,
.btn-primary.btn:first-child:active {
    color: white !important;
}

.bg-gray {
    background-color: #ebebeb !important;
}

/**********************************/
/********* Breadcrumb CSS *********/
/**********************************/

.breadcrumb-wrap {
    position: relative;
    width: 100%;
}

.breadcrumb-wrap .breadcrumb {
    margin: 0;
    padding: 0;
    background: transparent;
}

.breadcrumb-wrap .breadcrumb-item {
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
}

.breadcrumb-wrap .breadcrumb-item a {
    color: #1c8a45 !important;
    text-decoration: none !important;
}

.breadcrumb-wrap .breadcrumb-item a:hover {
    text-decoration: underline !important;
}