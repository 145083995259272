a {
    cursor: pointer;
}

.container .account-login {
    padding: 0 15px;
    border-right: 1px #eaeaea solid;
    border-left: 1px #eaeaea solid;
    margin: 0 auto;
}

.container .account-login#login {
    margin-top: 25px;
}

.mat-card {
    margin: 0 !important;
    padding: 0 !important;
    position: fixed !important;
    width: 100%;
    z-index: 1;
}

.mat-progress-bar-fill::after {
    background-color: #0c812c;
}

.section-progress-bar {
    display: flex;
    align-content: center;
    align-items: center;
    height: 3px;
}

.body {
    padding-left: 10px;
    padding-top: 10px;
}

a.button {
    display: inline-block;
    background-color: #efecec;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    border: none;
    border-radius: 999px;
    letter-spacing: 1px;
    padding: 12px 20px 10px 20px;
    font-family: "futura-pt", sans-serif;
}

aside ul li a {
    font-size: 13px !important;
}

.toolbar .pager {
    width: 100%;
}

.pager li>a,
.pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0px;
}

.ngx-pagination .disabled {
    padding: 0 !important;
}

.pager .pages li {
    margin: 0 2px !important;
}

.ml-2 {
    margin-left: 5px;
}

.sep-2 {
    margin: 0 5px;
}

.ml-10 {
    margin-left: 10px;
}

.sep-10 {
    margin: 0 10px;
}

.button.button-submit {
    background-color: #0c812c;
    color: #fff;
}

.button.button-cancel,
.button.button-cancel:hover {
    background-color: #ed6663;
    color: #fff;
}

.button.button-back:hover {
    background-color: #ed6663;
    color: #fff;
}

.pager .pages li a {
    box-shadow: none;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 0px;
    color: #333;
    background: #fff;
}

.ngx-pagination .current {
    background: none !important;
    padding: 0 !important;
}

.ngx-pagination .small-screen {
    display: none !important;
}

.pages .ngx-pagination>.current>a,
.pages .ngx-pagination>.current>span,
.pages .ngx-pagination>.current>a:hover,
.pages .ngx-pagination>.current>span:hover,
.pages .ngx-pagination>.current>a:focus,
.pages .ngx-pagination>.current>span:focus {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #88be4c;
    border-color: #88be4c;
    border-radius: 0px;
    border-width: 1px;
}

.fade.show {
    opacity: 1;
}

.require-star {
    color: #ed6663;
}

.box-account p label {
    font-weight: bold;
    min-width: 200px;
}

.col2-set .box {
    position: relative;
}

.col2-set .box-title::before {
    height: 2px;
    background: #ed6663;
    ;
    width: 30%;
    content: " ";
    position: absolute;
    margin-top: 8px;
    margin-left: 150px;
}

.col2-set #shipping-box.box-title::before {
    margin-left: 185px;
}

.col2-set h5 {
    color: #ed6663;
    margin-bottom: 15px;
}

button.button.button-md {
    padding: 5px 12px 5px 17px;
    font-size: 12px;
}

.delete-message {
    display: none;
}

.panel form input[type="text"],
.panel form select,
.panel form input[type="password"] {
    display: block;
    width: 100%;
}

.panel form label {
    font-weight: 700;
}


#user-edit-form textarea {
    background: #fff;
    border: 1px solid #d1d1d1;
    padding: 10px 15px;
    outline: none;
    width: 100%;
    border-radius: 999px;
    height: 100px;
}

#edit-picture-link {
    display: inline-block;
    max-width: 120px;
    width: 100%;
    position: relative;
}

#edit-picture-link span {
    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    padding: 50% 0;
}

#file-input {
    display: none;
}

.group-select li select {
    background: #fff;
    border: 1px solid #d1d1d1;
    margin-top: 4px;
    padding: 10px 15px;
    outline: none;
    width: 99%;
}

.group-select li textarea {
    background: #fff;
    border: 1px solid #d1d1d1;
    margin-top: 4px;
    border-radius: 999px;
    min-height: 100px;
    padding: 10px 30px;
    outline: none;
    width: 99%;
}

#productsList {
    width: 50%;
    list-style: none;
    font-size: 1.1em;
    color: rgb(105, 105, 105);
    border: 1px solid #ccc;
    border-top: none;
    position: absolute;
    left: 6%;
    margin-top: 2px;
    max-height: calc(50% - 15px);
    overflow-y: auto;
    background: #fff;
    display: none;
}

.tags {
    font-size: 12px;
    font-style: italic;
    color: #c6c6c6;
    margin-right: 10px;
    position: relative;
    top: -10px;
}


#productsList li {
    cursor: pointer;
    padding: 8px 10px;
    margin: 0;
}


#productsList li:hover {
    background: #88be4c;
    color: #fff
}

#productsList li:hover .tags {
    color: #fff;
}


.group-select li input.input-text.ng-touched.ng-invalid,
.group-select li textarea.ng-touched.ng-invalid,
.group-select li .input-box input.input-text.ng-touched.ng-invalid,
.group-select li .input-box textarea.ng-touched.ng-invalid {
    border: 1px solid #ff0005;
}


.product-section {
    margin: 0 20px;
}

.product-section .with-border {
    padding: 10px;
    border: 1px solid #dedede;
    width: 100%;
    min-height: 200px;
    position: relative;
}

#offer-product-images .with-border {
    background: #f2f2f2;
}


.product-section table td,
.product-section>* {
    font-size: 110%;
}


.product-section table label {
    font-weight: bold;
    min-width: 65px;
}

.product-section h4 {
    color: #0c812c;
    text-transform: initial;
}


#product-title {
    color: #0c812c;
    font-size: 200%;
}

.input-disabled {
    background: #e6e6e6 !important;
}

#browseBtnBlock {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.status {
    margin-top: 8px;
}

#offer-product-images image-cropper {
    visibility: hidden;
    position: absolute;
}

#browseBtnBlock button,
.status button,
#payment-methods-list button,
#offer-shipping-offers button {
    background: #0c812c;
    color: #fff;
}

#browseBtnBlock button:hover,
.status button:hover,
#payment-methods-list button:hover,
#offer-shipping-offers button:hover {
    color: #0c812c;
    background: #fff;
}

#product-block-page .btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a
}

#product-block-page .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925
}

#shipping-offers-list li span.float-li {
    float: left;
}

#shipping-offers-list li,
#shipping-offers-list li p {
    position: relative;
}

.clear-fix {
    content: ' ';
    clear: both;
    display: block;
}

.btn-remove:before {
    content: "\f014";
    font-family: FontAwesome;
}

.btn-remove:hover {
    color: #fff;
    background-color: #ed6663;
    border: 1px #ed6663 solid;
}

.btn-edit-2:hover {
    color: #fff;
    background-color: #0c812c;
    border: 1px #0c812c solid;
}

.btn-remove,
.btn-edit-2 {
    background-color: inherit;
    background-image: none;
    color: #333;
    cursor: pointer;
    padding: 5px;
    border-radius: 0px;
    text-decoration: none;
    transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    line-height: 12px;
    border: medium none;
    margin-left: 8px;
    font-size: 18px;
}



.btn-edit-2:before {
    content: "\f14b";
    font-family: FontAwesome;
}


.screenshots {
    position: relative;
    z-index: 100;
}

.screenshots .product-img {
    /* float: left;*/
    margin-right: 16px;
    float: left;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #ccc;
    align-items: center;
    position: relative;
    ;
}

.screenshots .product-img:last-child {
    margin-right: 0;
}

.screenshots .product-img span {
    padding: 2px;
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #737272;
    background: #ffffff94;
    cursor: pointer;
    display: none;
}

.screenshots .product-img:hover span {
    display: inline;
}

.screenshots .product-img span i {
    color: #ff0005;
}

.product-img img {
    width: 120px;
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.fe-loader {
    width: 25px;
    height: 25px;
    display: inline-block;
    border-top-color: #0c812c;
}

.payment {
    float: left;
    display: flex;
    width: 70px;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    margin-right: 10px;
}

.payment img {
    margin-left: 5px;
    width: 50px;
}

.btn.btn-block.btn-danger {
    background: #ff5f2e;
    border: 1px solid #fcfcfc;
}

.panel-success {
    border-color: #0c812c;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    input[type=date],
    input[type=time],
    input[type=datetime-local],
    input[type=month] {
        line-height: normal;
    }
}


/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 767px) and (min-width: 480px) {
    .container .account-login {
        width: 100%;
    }
}


.loader-box {
    display: flex;
    width: 100%;
    height: 250px;
    align-items: center;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #0c812c;
    border-color: #0c812c transparent #0c812c transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}



.mini-cart .actions .btn-cart {
    background: #0c812c;
    color: #fff;
    font-size: 18px;
    text-shadow: none;
    padding: 10px 0px;
    margin-top: 0px;
    font-weight: 500;
    border-radius: 999px;
    display: block;
    width: 100%;
    font-family: "futura-pt", sans-serif;
    text-transform: uppercase;
    border: none;
    letter-spacing: 1px;
}

.mini-cart .actions .btn-cart:before {
    content: "\f07a";
    font-family: FontAwesome;
    font-size: 18px;
    padding-right: 10px;
}


@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.input-box .is-invalid {
    border: 1px solid red !important;
}

.navbar-form .search-btn .glyphicon-search::before {
    content: " ";
}

.navbar-form .search-btn .glyphicon-search {
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    display: block ruby;
}

.navbar-form .search-btn .glyphicon-search.fr {
    background-image: url("/assets/images/flags/fr-flag.png");
}

.navbar-form .search-btn .glyphicon-search.en {
    background-image: url("/assets/images/flags/en-flag.png");
}

.evMtho {
    display: none !important;
}

.product-view .product-img-box .product-image .more-views-items {
    margin: 0 5px 0 0;
    border: 1px #e5e5e5 solid;
    width: 20%;
    float: left;
}


footer {
    padding: 0px 0 0;
    overflow: hidden;
    color: #fff;
    background: #252932;

}

.footer-top {
    clear: both;
    overflow: hidden;
    padding: 30px 0;
    border-top: 1px rgba(255, 255, 255, 0.1) solid;
}

.coppyright {
    color: #888;
    display: block;
    text-align: center;
    font-size: 13px;
    padding-top: 15px;
}

footer ul {
    margin: 0px;
    padding: 0px;
}

footer ul li {
    list-style-type: none;
}

footer ul li:last-child {
    border-bottom: none;
    width: auto
}

footer a {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px
}

.com-add {
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 10px;
    padding-bottom: 6px;
}

.footer-banner {
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 40px;
    clear: both;
    float: left
}

footer .footer-inner {
    margin: auto;
    overflow: hidden;


}

footer .footer-inner h3 {
    color: #3D4C4F;
    font-size: 15px;
    text-transform: uppercase;
    margin: 0 0 15px;
    padding: 0 0 10px;
    font-family: 'Open Sans', sans-serif;
}

.footer-column-1 {
    margin-right: 30px;
    margin-bottom: 15px;
    line-height: 18px;
    min-height: 220px;
    padding-right: 30px;
}

.footer-column {
    margin-bottom: 40px;

}

footer .co-info {
    margin-right: 0px !important;
    padding: 30px 0px;
    text-align: left;
    margin-top: 0px;


}

footer .newsletter-row {
    overflow: hidden;
    width: 100%;
    padding: 0px 0px;
    background: url(../images/slider-07.jpg) no-repeat top left;
    padding-bottom: 90px
}



footer .newsletter-row .col1 {
    text-align: center
}

footer .footer-column a {
    transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}

.footer-column-last {
    margin-right: 0px;
    margin-bottom: 15px;
    padding-left: 0px;
}

.footer-column-last li {
    padding: 0px 0 5px;
}

.footer-bottom .inner {
    margin: auto;
    padding: 20px 0 15px;
    height: 60px;
}

.footer-bottom .inner a {
    color: #aaa
}

.footer-bottom .inner .bottom_links a {
    margin-left: 15px;
}

.footer-bottom .inner a:hover {
    color: #fff
}

footer p {
    font-size: 14px;
    color: #222;
    padding-top: 5px;
    padding-bottom: 5px;
}

footer .footer-column a {
    display: block;
    color: #b7bcc8
}

footer a {
    line-height: normal;
}

footer a:hover {
    color: #88be4c;
}

footer p {
    line-height: 20px;
}

footer h4 {
    padding: 0 0 18px;
    font-size: 16px;
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-family: "futura-pt", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 50px;
}

footer .input-text:focus {
    background: #fff;
    border-color: #464646;
}

footer a:hover {
    text-decoration: none;
}

footer .coppyright {
    color: #b7bcc8;
    float: left
}

.footer-bottom {
    padding: 0px;
    overflow: hidden;
    width: 100%;
    margin: auto;
    padding: 25px 0;
    background-color: #1b1e24;
    text-align: center
}

.footer-bottom a {
    color: #999;
}

.footer-bottom .company-links ul {
    padding: 0px;
}

.footer-bottom .company-links li {
    display: inline-block;
    margin-left: 20px;
    list-style: none;
    float: right;
    border-bottom: none
}